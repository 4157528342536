<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page back>
        <el-row>
            <el-col :span="20">
                <el-form
                    ref="addForm"
                    size="small"
                    :model="formModel"
                    :rules="formRules"
                >
                    <el-form-item
                        prop="supplierName"
                        :label="$t('pageKey103', '供应商名称')"
                        label-width="13em"
                    >
                        <el-input
                            v-model="formModel.supplierName"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="principalName"
                        :label="$t('pageKey105', '联系人姓名')"
                        label-width="13em"
                    >
                        <el-input
                            v-model="formModel.principalName"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="principalPosition"
                        :label="$t('pageKey108', '联系人职务')"
                        label-width="13em"
                    >
                        <el-input
                            v-model="formModel.principalPosition"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="principalMobile"
                        :label="$t('pageKey106', '联系人手机')"
                        label-width="13em"
                    >
                        <el-input
                            v-model="formModel.principalMobile"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="principalEmail"
                        :label="$t('pageKey109', '联系人邮箱')"
                        label-width="13em"
                    >
                        <el-input
                            v-model="formModel.principalEmail"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="principalQq"
                        :label="$t('pageKey110', '联系人QQ')"
                        label-width="13em"
                    >
                        <el-input
                            v-model="formModel.principalQq"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="supplierRegionId"
                        :label="$t('pageKey111', '所在地区')"
                        label-width="13em"
                    >
                        <CascaderPicker
                            :api-class="selectApi"
                            v-model="formModel.supplierRegionId"
                            :placeholder="$t('pageKey101', '请选择')"
                            :p-id="2"
                            :min-lv="2"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="supplierAddress"
                        :label="$t('pageKey112', '详细地址')"
                        label-width="13em"
                    >
                        <el-input
                            v-model="formModel.supplierAddress"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="goodsSendMode"
                        :label="$t('pageKey113', '商品发货方式')"
                        label-width="13em"
                    >
                        <el-select
                            v-model="formModel.goodsSendMode"
                            :placeholder="$t('pageKey101', '请选择')"
                        >
                            <el-option
                                v-for="item in goodsSendModeList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        prop="remarks"
                        :label="$t('pageKey107', '备注')"
                        label-width="13em"
                    >
                        <el-input
                            type="textarea"
                            v-model="formModel.remarks"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="isAvailable"
                        :label="$t('pageKey12', '是否可用')"
                        label-width="13em"
                    >
                        <el-switch
                            v-model="formModel.isAvailable"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="regionId"
                        :label="$t('pageKey114', '退货地址')"
                        label-width="13em"
                    >
                        <CascaderPicker
                            :api-class="selectApi"
                            v-model="formModel.regionId"
                            :placeholder="$t('pageKey101', '请选择')"
                            :p-id="2"
                            :min-lv="2"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="address"
                        :label="$t('pageKey115', '退货详细地址')"
                        label-width="13em"
                    >
                        <el-input
                            v-model="formModel.address"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="consignee"
                        :label="$t('pageKey116', '收件人姓名')"
                        label-width="13em"
                    >
                        <el-input
                            v-model="formModel.consignee"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="mobile"
                        :label="$t('pageKey117', '收件人电话')"
                        label-width="13em"
                    >
                        <el-input
                            v-model="formModel.mobile"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="comments"
                        :label="$t('pageKey118', '退货备注')"
                        label-width="13em"
                    >
                        <el-input
                            type="textarea"
                            v-model="formModel.comments"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        :label="$t('pageKey119', '可售分销商')"
                        label-width="13em"
                        v-if="userData.shop && userData.shop.shopStoreType !== 99"
                    >
                        <div
                            class="ma-b"
                        >
                            <el-button-group>
                                <el-button
                                    type="success"
                                    icon="el-icon-plus"
                                    size="small"
                                    @click="onAddSelect"
                                    :disabled="isEdit"
                                >
                                    {{ $t('pageKey120', '新增分销商') }}
                                </el-button>
                            </el-button-group>
                        </div>
                        <!-- 表格 -->
                        <el-table
                            stripe
                            border
                            size="small"
                            :data="selectDataList"
                            style="width: 100%"
                        >
                            <el-table-column
                                prop="mobile"
                                :label="$t('pageKey121', '电话')"
                                min-width="100"
                            />
                            <el-table-column
                                prop="distributorName"
                                :label="$t('pageKey122', '分销商姓名')"
                                min-width="100"
                            />
                            <el-table-column
                                fixed="right"
                                :label="$t('pageKey13', '操作')"
                                width="45"
                            >
                                <template slot-scope="scope">
                                    <el-button
                                        type="text"
                                        size="small"
                                        @click="onDeleteSelect(scope.row)"
                                    >
                                        {{ $t('pageKey17', '删除') }}
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                    <el-form-item
                        prop="userId"
                        :label="$t('pageKey123', '关联用户')"
                        label-width="13em"
                    >
                        <RemoteSelect
                            clearable
                            filterable
                            remote="/rs/user/data"
                            :placeholder="$t('pageKey101', '请选择')"
                            :props="{id:'id',name:'selectName'}"
                            v-model="formModel.userId"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="printerSn"
                        :label="$t('pageKey124', '打印机编码')"
                        label-width="13em"
                    >
                        <el-input
                            v-model="formModel.printerSn"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="isAutoPrint"
                        :label="$t('pageKey125', '是否自动打印')"
                        label-width="13em"
                    >
                        <el-switch
                            v-model="formModel.isAutoPrint"
                        />
                    </el-form-item>
                    <el-form-item
                        label-width="13em"
                    >
                        <el-button
                            type="primary"
                            size="small"
                            @click="onConfirm"
                        >
                            {{ $t('pageKey73', '保 存') }}
                        </el-button>
                        <el-button
                            size="small"
                            @click="$router.back()"
                        >
                            {{ $t('pageKey126', '返 回') }}
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>

        <!-- 选择会员 -->
        <el-dialog
            :title="$t('pageKey127', '选择分销商')"
            center
            width="1200px"
            :visible.sync="selectDialog.isVisible"
            @closed="onSelectCancel"
        >
            <!-- 表单区 -->
            <el-form
                ref="selectDialogQueryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="selectDialog.queryFormModel"
            >
                <el-form-item
                    prop="distributorName"
                    :label="$t('pageKey122', '分销商姓名')"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.distributorName"
                        :placeholder="$t('pageKey128', '请输入分销商姓名')"
                    />
                </el-form-item>
                <el-form-item
                    prop="mobile"
                    :label="$t('pageKey121', '电话')"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.mobile"
                        :placeholder="$t('pageKey129', '请输入电话')"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onSelectDialogQuery()"
                    >
                        {{ $t('pageKey9', '查询') }}
                    </el-button>
                    <el-button
                        type="success"
                        @click="onResetSelectDialog"
                    >
                        {{ $t('pageKey10', '重置') }}
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 内容 -->
            <div class="body">
                <!-- 表格 -->
                <el-table
                    stripe
                    border
                    size="small"
                    :data="selectDialog.tableData"
                    style="width: 100%"
                    height="400"
                    row-key="id"
                    ref="selectDialogTable"
                    @selection-change="onSelectDialogSelectionChange"
                >
                    <el-table-column
                        type="selection"
                        :reserve-selection="true"
                        width="36"
                    />
                    <el-table-column
                        prop="mobile"
                        :label="$t('pageKey121', '电话')"
                        min-width="100"
                    />
                    <el-table-column
                        prop="distributorName"
                        :label="$t('pageKey122', '分销商姓名')"
                        min-width="100"
                    />
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="selectDialog.pagination"
                        @input="onSelectDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onSelectConfirm"
                >
                    {{ $t('pageKey19', '确 定') }}
                </el-button>
                <el-button
                    size="small"
                    @click="onSelectCancel"
                >
                    {{ $t('pageKey20', '取 消') }}
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'SupplierEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
                supplierName: '',
                principalName: '',
                principalPosition: '',
                principalMobile: '',
                principalEmail: '',
                principalQq: '',
                supplierRegionId: '',
                supplierAddress: '',
                remarks: '',
                isAvailable: true,
                regionId: '',
                address: '',
                consignee: '',
                mobile: '',
                comments: '',
                goodsSendMode: '',
                userId: '',
                printerSn: '',
                isAutoPrint: false,
            },
            // 表单校验规则
            formRules: {
                supplierName: {
                    required: true,
                    message: this.$t('pageKey104', '请输入供应商名称'),
                    trigger: 'blur',
                },
                principalName: {
                    required: true,
                    message: this.$t('pageKey130', '请输入联系人姓名'),
                    trigger: 'blur',
                },
                principalMobile: {
                    required: true,
                    message: this.$t('pageKey131', '请输入联系人电话'),
                    trigger: 'blur',
                },
                supplierRegionId: {
                    required: true,
                    message: this.$t('pageKey132', '请选择供应商地址'),
                    trigger: 'blur',
                },
                supplierAddress: {
                    required: true,
                    message: this.$t('pageKey133', '请输入供应商详细地址'),
                    trigger: 'blur',
                },
                regionId: {
                    required: true,
                    message: this.$t('pageKey134', '请选择退货地址'),
                    trigger: 'blur',
                },
                goodsSendMode: {
                    required: true,
                    message: this.$t('pageKey135', '请选择送货方式'),
                },
                address: {
                    required: true,
                    message: this.$t('pageKey136', '请输入退货详细地址'),
                    trigger: 'blur',
                },
                consignee: {
                    required: true,
                    message: this.$t('pageKey137', '请输入收件人姓名'),
                    trigger: 'blur',
                },
                mobile: {
                    required: true,
                    message: this.$t('pageKey138', '请输入收件人电话'),
                    trigger: 'blur',
                },
            },
            selectApi: this.$api.Rs.Region,
            selectDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    distributorName: '',
                    mobile: '',
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                // 当前选择行
                currentTableSelect: [],
            },
            selectDataList: [],
            goodsSendModeList: [],
        };
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                const buyerIdList = [];
                this.selectDataList.forEach(item => {
                    buyerIdList.push(item.id);
                });
                this.$api.Gd.Supplier.save({
                    ...this.formModel,
                    allowDistributorBuyerId: buyerIdList.join(','),
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        init() {
            this.$api.Gd.Supplier.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(this.formModel, res);
                if (res.buyerList) {
                    this.selectDataList = res.buyerList;
                }
            });
        },
        onAddSelect() {
            this.selectDialog.isVisible = true;
            this.$nextTick(() => {
                this.onSelectDialogQuery();
                // 初始化已选择数据
                if (this.$refs.selectDialogTable) {
                    this.$refs.selectDialogTable.clearSelection();
                }
                this.selectDataList.forEach(item => {
                    this.$refs.selectDialogTable.toggleRowSelection(item, true);
                });
            });
        },
        onSelectDialogQuery(pagination) {
            return this.$api.Mb.Buyer.selectByPage({
                ...this.selectDialog.queryFormModel,
                ...pagination,
                isDistributor: 1,
            }).then(json => {
                const res = json.data;
                this.selectDialog.tableData = res.data;
                this.selectDialog.pagination = res.pagination;
            });
        },
        onSelectDialogSelectionChange(val) {
            this.selectDialog.currentTableSelect = val;
        },
        onResetSelectDialog() {
            this.$refs.selectDialogQueryForm.resetFields();
        },
        onSelectConfirm() {
            this.selectDataList = [...this.selectDialog.currentTableSelect];
            this.selectDialog.isVisible = false;
        },
        onSelectCancel() {
            this.selectDialog.isVisible = false;
        },
        onDeleteSelect(row) {
            this.selectDataList.splice(this.selectDataList.indexOf(row), 1);
        },
        initGoodsSendModeList() {
            this.$api.Sp.Shop.getShopGoodsSendModeList({}).then(json => {
                this.goodsSendModeList = json.data.data;
            });
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.init();
        }
        this.initGoodsSendModeList();
    },
};
</script>

<style lang="scss">
</style>
