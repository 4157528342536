<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="supplierName"
                    :label="$t('pageKey103', '供应商名称')"
                >
                    <el-input
                        v-model="queryFormModel.supplierName"
                        :placeholder="$t('pageKey104', '请输入供应商名称')"
                    />
                </el-form-item>
                <el-form-item
                    prop="isAvailable"
                    :label="$t('pageKey5', '状态')"
                >
                    <el-select
                        v-model="queryFormModel.isAvailable"
                        :placeholder="$t('pageKey101', '请选择')"
                    >
                        <el-option
                            :label="$t('pageKey6', '全部')"
                            value=""
                        />
                        <el-option
                            :label="$t('pageKey7', '启用')"
                            value="1"
                        />
                        <el-option
                            :label="$t('pageKey8', '禁用')"
                            value="0"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        {{ $t('pageKey9', '查询') }}
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        {{ $t('pageKey10', '重置') }}
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="$router.push('/sp/supplier/supplier/edit')"
                    >
                        {{ $t('pageKey15', '新增') }}
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                :empty-text="$t('pageKey977', '暂无数据')"
            >
                <el-table-column
                    prop="supplierName"
                    :label="$t('pageKey103', '供应商名称')"
                    min-width="100"
                />
                <el-table-column
                    prop="principalName"
                    :label="$t('pageKey105', '联系人姓名')"
                    min-width="100"
                />
                <el-table-column
                    prop="principalMobile"
                    :label="$t('pageKey106', '联系人手机')"
                    min-width="100"
                />
                <el-table-column
                    prop="remarks"
                    :label="$t('pageKey107', '备注')"
                    min-width="100"
                />
                <el-table-column
                    prop="isAvailable"
                    :label="$t('pageKey12', '是否可用')"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        <el-switch
                            v-model="scope.row.isAvailable"
                            @input="onIsAvailableInput($event,scope)"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    :label="$t('pageKey55', '创建时间')"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.insertTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    :label="$t('pageKey57', '修改时间')"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.updateTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    :label="$t('pageKey13', '操作')"
                    width="150"
                >
                    <template slot-scope="scope">
                        <!--                        <el-button
                                        type="text"
                                        size="small"
                                        @click="$router.push('/sp/supplier/supplier/goodsSaleStat?supplierId=' + scope.row.id)"
                                    >
                                        销量统计
                                    </el-button>-->
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/sp/supplier/supplier/edit/' + scope.row.id)"
                        >
                            {{ $t('pageKey16', '编辑') }}
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableDelete(scope.row.id)"
                        >
                            {{ $t('pageKey17', '删除') }}
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'Supplier',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                supplierName: '', // 供应商名称
                isAvailable: '', // 是否可用
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Gd.Supplier.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onTableDelete(ids) {
            this.$confirm(this.$t('pageKey41', '是否确定要删除!'), this.$t('pageKey42', '温馨提示'), {
                type: 'warning',
                confirmButtonText: this.$t('pageKey19', '确定'),
                cancelButtonText: this.$t('pageKey20', '取消'),
            }).then(() => {
                this.$api.Gd.Supplier.delete({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onDelete() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            this.onTableDelete(ids.join(','));
        },
        onIsAvailableInput(val, scope) {
            const { row } = scope;
            if (!row) return;
            this.$api.Gd.Supplier.status({
                id: row.id,
                isAvailable: row.isAvailable,
            })
                .then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                })
                .catch(() => {
                    this.$message({
                        message: this.$t('pageKey43', '操作失败请重试'),
                        type: 'error',
                    });
                    row.isAvailable = !row.isAvailable;
                });
        },
    },
};
</script>

<style lang="scss">
</style>
